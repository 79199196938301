import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import store from './store'
import './bootstrap.min.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(
  `pk_live_51JSHsFFTun8dXRXwDHm6E1y0UPlTZj8GKh5kXsG4dt5pecfP25ezBzF63vgodZMp58VwSOtz8RthmkfqgINqN5qY0043KK3dwR`
)

ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
