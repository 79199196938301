import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classes from './SubmissionCodePaymentsListScreen.module.css'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import axios from 'axios'
import Searchbar from '../components/Searchbar'

const SubmissionCodePaymentsListScreen = () => {
  const [paymentsList, setPaymentsList] = useState([])
  const [search, setSearch] = useState('')
  const [confName, setConfName] = useState('')
  const [confCurrency, setConfCurrency] = useState('')
  const [confPosterPrice, setConfPosterPrice] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const navigate = useNavigate()

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  }

  let params = useParams()

  const getConferenceDetails = async () => {
    try {
      const { data: conference } = await axios.get(
        `/api/conferences/${params.prefix}`
      )

      setConfName(conference.name)
      setConfCurrency(conference.currency)
      setConfPosterPrice(conference.posterPrice)
    } catch (error) {
      //TODO: Redirect to conference list screen
      const status = error.response.status
      navigate(`/admin/conferencelist`)
      console.log(status)
    }
  }

  const getPaidSubmissionCodesByPrefix = async () => {
    try {
      const { data } = await axios.get(
        `/api/submissioncodes/paymentslist/${params.prefix}`,
        config
      )
      setPaymentsList(data)
    } catch (error) {}
  }

  useEffect(() => {
    getPaidSubmissionCodesByPrefix()
    getConferenceDetails()
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.paymentsListContainer}>
        <h2>ePostersLive</h2>
        <h3>Payments List for {confName}</h3>
        <Row>
          <Col>
            <Searchbar
              value={search}
              placeholder='Search by UID'
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <Col>
            Currency: {confCurrency === 'eur' ? 'Euros - €' : 'Dollars - $'}
          </Col>
          <Col>Total Number of Payments: {paymentsList.length}</Col>
          <Col>
            Total Amount:{' '}
            {paymentsList.reduce(
              (partialSum, payment) => partialSum + payment.price,
              0
            )}{' '}
            {confCurrency === 'eur' ? ' €' : ' $'}
          </Col>
        </Row>
        <table className={classes.paymentsListContainer}>
          <tr
            style={{
              color: '#7787ab',
              backgroundColor: '#F7F8FC',
              margin: '0',
              boxShadow: '0 0 0 0 #ffffff !important',
            }}
            className={classes.paymentsListItemContainer}
          >
            <th className={classes.paymentsListUIDContainer}>UID</th>
            <th className={classes.paymentsListAuthorEmailContainer}>
              Author Email
            </th>
            <th className={classes.paymentsListPaidByContainer}>Paid By</th>
            <th className={classes.paymentsListPriceContainer}>Price</th>
          </tr>
          {paymentsList.map(
            (paymentList) =>
              paymentList.uniqueIdentifier.includes(
                search.trim().toLowerCase()
              ) && (
                <tr
                  className={classes.paymentsListItemContainer}
                  key={paymentList.abstractId}
                >
                  <td className={classes.paymentsListUIDContainer}>
                    {paymentList.uniqueIdentifier}
                  </td>
                  <td className={classes.paymentsListAuthorEmailContainer}>
                    {paymentList.authorEmail}
                  </td>
                  <td className={classes.paymentsListPaidByContainer}>
                    {paymentList.paidBy}
                  </td>
                  <td className={classes.paymentsListPriceContainer}>
                    {paymentList.price} {confCurrency === 'eur' ? ' €' : ' $'}
                  </td>
                </tr>
              )
          )}
        </table>
        <Row className={classes.paymentsListButtonContainer}>
          <Col>
            <LinkContainer to={`/admin/conferencelist/`}>
              <Button variant='info' className='btn-sm buttonCta'>
                Back to Conference List
              </Button>
            </LinkContainer>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SubmissionCodePaymentsListScreen
