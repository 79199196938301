import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useStripe } from '@stripe/react-stripe-js'
import FormContainer from '../components/FormContainer'
import { Button, Form } from 'react-bootstrap'
import Message from '../components/Message'
import classes from './SubmissionCodePaymentScreen.module.css'
import Loader from '../components/Loader'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const SubmissionCodePaymentScreen = () => {
  const navigate = useNavigate()
  let params = useParams()
  const stripe = useStripe()
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  const [email, setEmail] = useState('')
  const [sentRequest, setSentRequest] = useState(false)
  const [multipleUnpaidCodes, setMultipleUnpaidCodes] = useState(0)
  const [multiplePaidCodes, setMultiplePaidCodes] = useState(0)
  const [confName, setConfName] = useState('')
  const [abstractCodes, setAbstractCodes] = useState([])
  const [fetchCodesLoading, setFetchCodesLoading] = useState(false)
  const [messageVariant, setMessageVariant] = useState('')
  const [messageText, setMessageText] = useState('')

  const goToStripeCheckout = async (sessionId) => {
    const checkoutResponse = await stripe.redirectToCheckout({
      sessionId,
    })

    console.log(checkoutResponse)
  }

  const getConferenceDetails = async () => {
    try {
      const { data: conference } = await axios.get(
        `/api/conferences/${params.prefix}`
      )
      setConfName(conference.name)
      if (conference.supportsPayments === false) {
        navigate(`/submissioncodes/${conference.prefix}`)
      }
    } catch (error) {
      const status = error.response.status
      if (status === 404) {
        navigate('/404')
      }
    }
  }

  const fetchAbstractCodes = async (authorEmail) => {
    // fetch codes from backend paid or not paid
    setFetchCodesLoading(true)
    try {
      const { data } = await axios.get(
        `/api/submissioncodes/fetch/${authorEmail}/${params.prefix}`
      )
      if (data.length === 0) {
        setMessageText(
          'We could not fetch any data. Please check if the email you are suggesting is correct. If you think it is please contact us at support@scigentech.com'
        )
        setMessageVariant('orange')
      }
      setAbstractCodes([...data])
      setMultipleUnpaidCodes(
        [...data].filter((code) => code.paid === false).length > 1
      )
      setMultiplePaidCodes(
        [...data].filter((code) => code.paid === true).length > 0
      )
      setFetchCodesLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const cardSubmitHandler = (e) => {
    e.preventDefault()
    setMessageText('')
    setMessageVariant('')
    fetchAbstractCodes(email)
  }

  const buyOneHandler = async (abstractId, authorEmail, prefix) => {
    try {
      const {
        data: { sessionId },
      } = await axios.post(
        `/api/stripe/create-checkout-session`,
        { abstractId, authorEmail, prefix, buyAll: false },
        config
      )
      goToStripeCheckout(sessionId)
    } catch (error) {
      const status = error.response.status
      if (status === 400) {
        setMessageText(
          'Sorry something went wrong. Please try refreshing the page. If the problem still exists please contact us at support@scigentech.com'
        )
        setMessageVariant('red')
      }
    }
  }

  const buyAllHandler = async (authorEmail, prefix) => {
    try {
      const {
        data: { sessionId },
      } = await axios.post(
        `/api/stripe/create-checkout-session`,
        { authorEmail, prefix, buyAll: true },
        config
      )
      goToStripeCheckout(sessionId)
    } catch (error) {
      const status = error.response.status
      if (status === 400) {
        setMessageText(
          'Sorry something went wrong. Please try refreshing the page. If the problem still exists please contact us at support@scigentech.com'
        )
        setMessageVariant('red')
      }
    }
  }

  const recallAbstractCodesHandler = async (authorEmail, prefix) => {
    try {
      const { status } = await axios.get(
        `/api/submissioncodes/email/${authorEmail}/${params.prefix}`
      )

      if (status === 200) {
        setMessageText(`An email has been sent to ${authorEmail}`)
        setMessageVariant('green')
      }
    } catch (error) {
      const status = error.response.status
      if (status === 500) {
        setMessageText('Something went wrong')
        setMessageVariant('red')
      }
      console.log(error)
    }
  }

  useEffect(() => {
    getConferenceDetails()
  }, [])

  return (
    <div className={classes.container}>
      {fetchCodesLoading ? (
        <Loader />
      ) : abstractCodes.length !== 0 ? (
        <div className={classes.abstractCodesContainer}>
          <h2>ePostersLive</h2>
          <Message
            text={messageText}
            variant={messageVariant}
            className='my-3'
          />
          <h3>Your posters for {confName}</h3>
          <div
            style={{
              color: '#7787ab',
              backgroundColor: '#F7F8FC',
              margin: '0',
              boxShadow: '0 0 0 0 #ffffff !important',
            }}
            className={classes.abstractItemContainer}
          >
            {/* <div className={classes.abstractIdContainer}>Abstract Id</div> */}
            <div className={classes.abstractTitleContainer}>Title</div>
            <div className={classes.abstractPayContainer}>Paid/Buy</div>
          </div>
          {abstractCodes.map((abstractCode) => (
            <div
              className={classes.abstractItemContainer}
              key={abstractCode.abstractId}
            >
              {/* <div className={classes.abstractIdContainer}>
                {abstractCode.abstractId}
              </div> */}
              <div
                className={classes.abstractTitleContainer}
                dangerouslySetInnerHTML={{
                  __html: abstractCode.abstractTitle,
                }}
              >
                {/* {abstractCode.abstractTitle} */}
              </div>
              <div className={classes.abstractPayContainer}>
                {abstractCode.paid ? (
                  <div className={classes.paid}>
                    <CheckBoxIcon style={{ color: '#97D12F' }} />
                  </div>
                ) : (
                  <Button
                    className={classes.buyButton}
                    onClick={() =>
                      buyOneHandler(
                        abstractCode.abstractId,
                        email,
                        params.prefix
                      )
                    }
                  >
                    Buy
                  </Button>
                )}
              </div>
            </div>
          ))}
          <div className={classes.ctaButtonContainer}>
            <Button
              type='button'
              className={classes.buyButton}
              onClick={() => setAbstractCodes([])}
            >
              Go Back
            </Button>
            {multipleUnpaidCodes && (
              <Button
                className={classes.buyButton}
                onClick={() => buyAllHandler(email, params.prefix)}
              >
                Buy All
              </Button>
            )}
            {multiplePaidCodes && (
              <Button
                className={classes.buyButton}
                onClick={() => recallAbstractCodesHandler(email, params.prefix)}
              >
                Email
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className={classes.cardContainer}>
          <div className={classes.flexItemLeft}>
            <h1>ePostersLive</h1>
            <h2>Pay to receive your ePoster Submission Code(s)</h2>
            <div>
              <p>
                Please use the form to find the codes of your conference.
                <br />
                <strong>
                  {' '}
                  Please note that for this conference you will have to pay{' '}
                  <br /> in order to receive your submission codes.
                </strong>
              </p>
              <p>
                If the email is correct the list of codes will appear right
                below.
              </p>
            </div>
          </div>
          <div className={classes.flexItemRight}>
            <div className={classes.emailLabel}>
              Enter the email address you used during <br /> abstract submission
              to <strong>{confName}</strong>.
            </div>
            <FormContainer>
              <Form onSubmit={cardSubmitHandler}>
                <Form.Group controlId='email'>
                  <Form.Label style={{ display: 'none' }}>
                    Enter the email address you used during abstract submission
                    to <strong>{confName}</strong>.
                  </Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='johndoe@example.com'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <div className={classes.buttonContainer}>
                  <Button
                    type='submit'
                    className={classes.cta}
                    disabled={!email.length}
                  >
                    Find Code(s)
                  </Button>
                </div>
              </Form>
            </FormContainer>
            <Message
              text={messageText}
              variant={messageVariant}
              className='my-3'
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default SubmissionCodePaymentScreen
