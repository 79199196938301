import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import FormContainer from '../components/FormContainer'
import { Button, Form } from 'react-bootstrap'
import axios from 'axios'
import Message from '../components/Message'
import classes from './SubmissionCodeRetrievalScreen.module.css'

const SubmissionCodeRetrievalScreen = () => {
  const navigate = useNavigate()
  let params = useParams()

  const primaryColor = 'green'
  const warningColor = 'orange'

  const [email, setEmail] = useState('')
  const [sentRequest, setSentRequest] = useState(false)
  const [confName, setConfName] = useState('')
  const [messageVariant, setMessageVariant] = useState(primaryColor)
  const [noDataMessage, setNoDataMessage] = useState('')

  const messageEmailNotFound =
    'We could not fetch any data. Please check if the email you are suggesting is correct. If you think it is please contact us at support@scigentech.com'
  const messageEmailFound = 'An email has been sent.'

  const sendEmail = async () => {
    // SEND EMAIL WITH CODES TO AUTHOR
    try {
      const { status } = await axios.get(
        `/api/submissioncodes/fetch/${email}/${params.prefix}`
      )
      setSentRequest(true)
      console.log(status)
      if (status === 204) {
        console.log('Did not find data for:', email)
        setNoDataMessage(messageEmailNotFound)
        setMessageVariant(warningColor)
      }
      if (status === 201) {
        console.log('Sending email to:', email)
        setNoDataMessage(messageEmailFound)
        setMessageVariant('green')
      }
    } catch (error) {
      // TODO: error handling
      const status = error.response.status
      if (status === 404) {
        setNoDataMessage(messageEmailFound)
        setMessageVariant('red')
      }
    }
  }

  const getConferenceDetails = async () => {
    try {
      const { data: conference } = await axios.get(
        `/api/conferences/${params.prefix}`
      )
      setConfName(conference.name)
      if (conference.supportsPayments) {
        navigate(`/submissioncodes/payments/${conference.prefix}`)
      }
      console.log('this is a free conf')
    } catch (error) {
      const status = error.response.status
      if (status === 404) {
        navigate('/404')
      }
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    sendEmail()
  }

  useEffect(() => {
    console.log('Rendered')
    getConferenceDetails()
  }, [])

  return (
    <div className={classes.cardContainer}>
      <div className={classes.flexItemLeft}>
        <h1>ePostersLive</h1>
        <h2>Receive your ePoster Submission Code(s)</h2>
        <div>
          <p>
            Because we will send your unique ePoster Submission Code via email,
            make sure that you enter the same email address you used during
            abstract submission.
          </p>
          <p>
            If you have more than one accepted ePosters, we will send you the
            ePoster Submission Code for each of those.
          </p>
        </div>
      </div>
      <div className={classes.flexItemRight}>
        <div className={classes.emailLabel}>
          Enter the email address you used during abstract submission to{' '}
          <strong>{confName}</strong>.
        </div>
        <FormContainer>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='email'>
              <Form.Label style={{ display: 'none' }}>
                Enter the email address you used during abstract submission to{' '}
                <strong>{confName}</strong>.
              </Form.Label>
              <Form.Control
                type='email'
                placeholder='johndoe@example.com'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <div className={classes.buttonContainer}>
              <Button
                type='submit'
                className={classes.cta}
                disabled={!email.length}
              >
                Send Email
              </Button>
            </div>
          </Form>
        </FormContainer>
        {sentRequest && (
          <Message
            text={noDataMessage}
            variant={messageVariant}
            className='my-3'
          />
        )}
      </div>
    </div>
  )
}

export default SubmissionCodeRetrievalScreen
