import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import HomeIcon from '@mui/icons-material/Home'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import LoginIcon from '@mui/icons-material/Login'
import SidebarItem from './SidebarItem'
import DocumentScanner from '@mui/icons-material/DocumentScanner'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import ModeCommentIcon from '@mui/icons-material/ModeComment'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import GraphicEqIcon from '@mui/icons-material/GraphicEq'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import SidebarSection from './SidebarSection'

const Sidebar = () => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userSelectedConfernce = useSelector(
    (state) => state.userSelectedConfernce
  )

  const { selectedConference } = userSelectedConfernce
  const isAdmin = userInfo?.isAdmin

  if (!userInfo) return <></>

  return (
    <div className='Sidebar'>
      <div className='SidebarBrand'>ePostersLive</div>
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <div className='SelectedConference'>{selectedConference}</div>
      </div>
      <ul className='SidebarList'>
        {isAdmin && (
          <SidebarSection title='Admin Panel'>
            <SidebarItem
              title='Analytics Users'
              link='/admin/userlist'
              icon={<SupervisedUserCircleIcon />}
            />
            <SidebarItem
              title='SC App'
              link='/admin/conferencelist'
              icon={<HistoryEduIcon />}
            />
          </SidebarSection>
        )}
        {userInfo && (
          <SidebarSection title='Analytics'>
            <SidebarItem
              title='Home'
              link='/analytics/home'
              icon={<HomeIcon />}
            />
            <SidebarItem
              title=' Overview'
              link={`/analytics/traffic/${selectedConference}`}
              icon={<AnalyticsIcon />}
            />
            <SidebarItem
              title=' Top Posters'
              link={`/analytics/posters/${selectedConference}`}
              icon={<DocumentScanner />}
            />
            <SidebarItem
              title=' Top Authors'
              link={`/analytics/authors/${selectedConference}`}
              icon={<AccountBoxIcon />}
            />
            <SidebarItem
              title=' Top Topics'
              link={`/analytics/topics/${selectedConference}`}
              icon={<ModeCommentIcon />}
            />
            <SidebarItem
              title=' Top Affiliations'
              link={`/analytics/affiliations/${selectedConference}`}
              icon={<AccountBalanceIcon />}
            />
            <SidebarItem
              title=' Top Videos'
              link={`/analytics/videos/${selectedConference}`}
              icon={<VideoLibraryIcon />}
            />
            <SidebarItem
              title=' Top Audios'
              link={`/analytics/audios/${selectedConference}`}
              icon={<GraphicEqIcon />}
            />
          </SidebarSection>
        )}

        {!userInfo && (
          <SidebarItem title='Login' link='/login' icon={<LoginIcon />} />
        )}
        {userInfo && (
          <SidebarItem title='Logout' link='/logout' icon={<LogoutIcon />} />
        )}
      </ul>
    </div>
  )
}

export default Sidebar
